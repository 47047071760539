import { differenceInYears } from 'date-fns';
import dayjs from 'dayjs';
import {
  Companion,
  CompanionEnum,
  Customer,
  CustomerEnum,
} from '../BookingWidget/BookingManagment/bookingManagment.interface';
import {
  FormQuotationInformationEnum,
  FormQuotationInformationPersonalData,
  FormQuotationInformationTravellerList,
} from './quoation-information.interface';

const prepareInitInformationPersonal = (
  customer: Customer,
): FormQuotationInformationPersonalData => ({
  [FormQuotationInformationEnum.Title]: customer[CustomerEnum.Title] as string,
  [FormQuotationInformationEnum.Firstname]: customer[
    CustomerEnum.FirstName
  ] as string,
  [FormQuotationInformationEnum.Lastname]: customer[
    CustomerEnum.LastName
  ] as string,
  [FormQuotationInformationEnum.Email]: customer[
    CustomerEnum.EmailAddress
  ] as string,
  [FormQuotationInformationEnum.ReenterEmail]:
    customer[CustomerEnum.EmailAddress],
  [FormQuotationInformationEnum.Phone]: customer[
    CustomerEnum.PhoneNumber1
  ] as string,
  [FormQuotationInformationEnum.Address]: customer[
    CustomerEnum.AddressLine1
  ] as string,
  [FormQuotationInformationEnum.Address2]: customer[
    CustomerEnum.AddressLine2
  ] as string,
  [FormQuotationInformationEnum.City]: customer[
    CustomerEnum.AddressLine3
  ] as string,
  [FormQuotationInformationEnum.State]: customer[
    CustomerEnum.AddressLine4
  ] as string,
  [FormQuotationInformationEnum.Postcode]: customer[
    CustomerEnum.PostalCode
  ] as string,
  [FormQuotationInformationEnum.Birthdate]: dayjs(
    customer[CustomerEnum.DateOfBirth],
  ).format('DD/MM/YYYY'),
});

const prepareInitInformationTravellers = (
  companions: Companion[],
): FormQuotationInformationTravellerList => {
  const travellers: FormQuotationInformationTravellerList = {};

  // eslint-disable-next-line array-callback-return
  companions.map((companion, index) => {
    const indexForPS = (index + 1).toString();
    travellers[
      `travellerFirstName${indexForPS}` as keyof FormQuotationInformationTravellerList
    ] = companion[CompanionEnum.FirstName];
    travellers[
      `travellerLastName${indexForPS}` as keyof FormQuotationInformationTravellerList
    ] = companion[CompanionEnum.LastName];
    travellers[
      `travellerAge${indexForPS}` as keyof FormQuotationInformationTravellerList
    ] = differenceInYears(
      new Date(),
      new Date(companion[CompanionEnum.DateOfBirth]),
    ).toString();
  });

  return travellers;
};

export { prepareInitInformationPersonal, prepareInitInformationTravellers };
